import {Button, Modal, Table, Tooltip, Space, Card, Typography, Badge, Input, Row, Col} from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined, ReloadOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';

import FileManagmentModal from './components/FileManagmentModal';
import FileUploadList from '../../FileUploadList';

const Deneme = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedPersonel, setSelectedPersonel] = useState(null); // Seçilen personel bilgisi
    const [companyFileList, setCompanyFileList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const scrollContainerRef = useRef(null);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: isMobile ? 5 : 10,
            showSizeChanger: !isMobile,
        },
    });

    useEffect(() => {
        // Ekran boyutu değişikliklerini dinle
        const handleResize = () => {
            const mobile = window.innerWidth <= 768;
            setIsMobile(mobile);
            setTableParams(prev => ({
                ...prev,
                pagination: {
                    ...prev.pagination,
                    pageSize: mobile ? 5 : 10,
                    showSizeChanger: !mobile,
                }
            }));
        };

        window.addEventListener('resize', handleResize);
        fetchCompanyFileList(props.companyId);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [props.companyId]);

    // Modal kapandığında yeniden tabloyu yükle
    useEffect(() => {
        if (!showModal) {
            fetchCompanyFileList(props.companyId); // Modal kapanınca yeniden dosya listesini yükle
        }
    }, [showModal, props.companyId]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
        
        // Scroll'u en başa getir
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = 0;
        }
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const refreshData = () => {
        setLoading(true);
        fetchCompanyFileList(props.companyId);
    };

    // Kurum Bazlı Formlar için tablo sütunları
    const companyColumns = [
        {
            id: 1,
            title: "Mağaza",
            dataIndex: "company",
            sorter: (a, b) => a.company.localeCompare(b.company),
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return record.company.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            id: 3,
            title: (
                <Tooltip title="Risk Değerlendirme Raporu">
                    <span>RDR</span>
                </Tooltip>
            ),
            dataIndex: "RISK_DEGERLENDIRME_RAPORU",
            render: (text, item) => renderStatus(item, "RISK_DEGERLENDIRME_RAPORU"),
        },
        {
            id: 4,
            title: (
                <Tooltip title="Acil Durum Planı">
                    <span>ADP</span>
                </Tooltip>
            ),
            dataIndex: "ACIL_DURUM_PLANI",
            render: (text, item) => renderStatus(item, "ACIL_DURUM_PLANI"),
        },
        {
            id: 5,
            title: (
                <Tooltip title="İş Sağlığı Uzmanı Atama">
                    <span>İSG Atama</span>
                </Tooltip>
            ),
            dataIndex: "ISG_UZMANI_ATAMA",
            render: (text, item) => renderStatus(item, "ISG_UZMANI_ATAMA"),
        },
        {
            id: 6,
            title: (
                <Tooltip title="İşyeri Hekimi Atama">
                    <span>İH Atama</span>
                </Tooltip>
            ),
            dataIndex: "ISYERI_HEKIMI_ATAMA",
            render: (text, item) => renderStatus(item, "ISYERI_HEKIMI_ATAMA"),
        },
        {
            id: 7,
            title: (
                <Tooltip title="Tespit Öneri Defter Nüs">
                    <span>TÖD Nüshası</span>
                </Tooltip>
            ),
            dataIndex: "TESPIT_ONERI_DEFTER",
            render: (text, item) => renderStatus(item, "TESPIT_ONERI_DEFTER"),
        },
        {
            id: 8,
            title: (
                <Tooltip title="İşveren Vekili Atama">
                    <span>İşveren Vekili</span>
                </Tooltip>
            ),
            dataIndex: "ISVEREN_VEKILI_ATAMA",
            render: (text, item) => renderStatus(item, "ISVEREN_VEKILI_ATAMA"),
        },
        {
            id: 9,
            title: (
                <Tooltip title="Çalışan Temsilcisi Atama">
                    <span>Çalışan Temsilcisi</span>
                </Tooltip>
            ),
            dataIndex: "CALISAN_TEMSILCI_ATAMA",
            render: (text, item) => renderStatus(item, "CALISAN_TEMSILCI_ATAMA"),
        },
        {
            id: 10,
            title: (
                <Tooltip title="Destek Elemanları Atama">
                    <span>Destek Elemanları</span>
                </Tooltip>
            ),
            dataIndex: "DESTEK_ELEMANI_ATAMA",
            render: (text, item) => renderStatus(item, "DESTEK_ELEMANI_ATAMA"),
        },
        {
            id: 11,
            title: (
                <Tooltip title="Yıllık Çalışma Planı">
                    <span>YÇP</span>
                </Tooltip>
            ),
            dataIndex: "YILLIK_CALISMA_PLANI",
            render: (text, item) => renderStatus(item, "YILLIK_CALISMA_PLANI"),
        },
        {
            id: 12,
            title: (
                <Tooltip title="Yıllık Değerlendirme Raporu">
                    <span>YDP</span>
                </Tooltip>
            ),
            dataIndex: "YILLIK_DEGERLENDIRME_RAPORU",
            render: (text, item) => renderStatus(item, "YILLIK_DEGERLENDIRME_RAPORU"),
        },
        {
            id: 13,
            title: (
                <Tooltip title="Eğitim Katılım Formu">
                    <span>Eğitim Katılım</span>
                </Tooltip>
            ),
            dataIndex: "EGITIM_KATILIM_FORMU",
            render: (text, item) => renderStatus(item, "EGITIM_KATILIM_FORMU"),
        },
    ];

    // Dosya durumu için ikonları döndüren fonksiyon
    const renderStatus = (item, fileType) => {
        const file = item.files.find(f => f.fileType === fileType);
        if (!file) return ""; // İlgili dosya tipi yoksa soru işareti göster

        const handleClick = () => {
            handleRowClick(item, fileType);
        };

        if (file.approved) {
            return (
                <span onClick={handleClick}>
                    <CheckCircleOutlined style={{ color: 'green' }} /> Onaylı
                </span>
            );
        }

        if (file.reject) {
            return (
                <span onClick={handleClick}>
                    <CloseCircleOutlined style={{ color: 'red' }} /> Ret
                </span>
            );
        }

        if (file.timeout) {
            return (
                <span onClick={handleClick}>
                    <ClockCircleOutlined style={{ color: 'orange' }} /> Süresi Geçmiş
                </span>
            );
        }

        return (
            <span onClick={handleClick}>
                <QuestionCircleOutlined /> Onay Bekliyor
            </span>
        );
    };

    // API çağrısı - Kurum Bazlı Formlar
    const fetchCompanyFileList = (companyId) => {
        setLoading(true);
        const sendData = {
            id: companyId
        };

        axios.post('/findFilesCompany', { method: "findFilesCompany", processor: "file", data: sendData })
            .then(response => {
                const data = response.data.data;
                if (data) {
                    // Gelen veriyi tabloya uygun şekilde formatlıyoruz
                    const formattedData = data.map(item => ({
                        companyId: item.companyId,
                        company: item.company,
                        personel: item.personel,
                        files: item.fileList
                    }));
                    setCompanyFileList(formattedData);
                    // Veri yüklendiğinde parent'a bildir
                    props.onDataLoaded?.(formattedData);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("An error occurred while fetching company file data: ", error);
                setLoading(false);
            });
    };

    // Satır tıklama eventi
    const handleRowClick = (record, fileType) => {
        if (!fileType) {
            return;
        }
        
        const filteredFile = record.files.find(f => f.fileType === fileType);

        if (filteredFile) {
            setShowModal(false);
            
            setTimeout(() => {
                const modifiedRecord = {
                    personel: record.personel,
                    files: [filteredFile],
                    company: record.company,
                    companyId: record.companyId,
                };
                
                setSelectedPersonel(modifiedRecord);
                setShowModal(true);
            }, 100);
        } else {
        }
    };

    // Filtreleme için kullanılan veri
    const filteredData = companyFileList.filter(
        item => item.company.toLowerCase().includes(searchText.toLowerCase())
    );

    // Satır sınıfı belirlemek için
    const rowClassName = (record, index) => {
        const baseClass = 'custom-row';
        // Belirli kriterlere göre ek sınıflar eklenebilir
        return baseClass;
    };

    // Komponentin yeniden renderlanmasında scrollu başa getir
    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = 0;
        }
    }, [props.companyId, searchText]);

    return (
        <Card
            title={
                <Space>
                    <Typography.Title level={isMobile ? 5 : 4} style={{ margin: 0 }}>
                        Mağaza Bazlı Belgeler
                    </Typography.Title>
                    <Badge count={companyFileList.length} showZero color="#1890ff" />
                </Space>
            }
            extra={
                <Space>
                    <Button 
                        type="primary" 
                        icon={<ReloadOutlined />} 
                        onClick={refreshData}
                        loading={loading}
                    >
                        {!isMobile && "Yenile"}
                    </Button>
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={() => setShowUploadModal(true)}
                    >
                        {!isMobile && "Belge Yükle"}
                    </Button>
                </Space>
            }
        >
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                <Col xs={24} md={12} lg={8}>
                    <Input.Search
                        placeholder="Mağaza adı ara..."
                        allowClear
                        onSearch={handleSearch}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </Col>
            </Row>

            {/* Modal */}
            <Modal
                title="Mağaza Bazlı Detay"
                footer={
                    <Button type="primary" onClick={() => setShowModal(false)}>
                        Kapat
                    </Button>
                }
                width={1300}
                open={showModal}
                centered={true}
                onCancel={() => setShowModal(false)}
                destroyOnClose={true}
                key={selectedPersonel?.companyId + '-' + selectedPersonel?.files[0]?.fileType}
            >
                {selectedPersonel && (
                    <FileManagmentModal
                        key={selectedPersonel.companyId + '-' + selectedPersonel.files[0]?.fileType}
                        companyId={selectedPersonel.companyId}
                        documentType={selectedPersonel.files[0]?.fileType}
                    />
                )}
            </Modal>

            {/* Belge Yükleme Modalı */}
            <Modal
                title="Belge Yükleme"
                footer={null}
                width={1300}
                open={showUploadModal}
                centered={true}
                onCancel={() => {
                    setShowUploadModal(false);
                    refreshData();
                }}
                destroyOnClose={true}
            >
                <FileUploadList 
                    defaultActiveKey="1"
                    selectedCompany={props.companyId}
                    onClose={() => {
                        setShowUploadModal(false);
                        refreshData();
                    }}
                />
            </Modal>

            <div 
                ref={scrollContainerRef} 
                style={{ overflowX: 'auto', width: '100%' }}
            >
                <Table
                    columns={companyColumns}
                    dataSource={filteredData}
                    rowKey="companyId"
                    loading={loading}
                    onChange={handleTableChange}
                    pagination={tableParams.pagination}
                    scroll={{ 
                        x: isMobile ? 1600 : 2400,
                        y: undefined,
                        scrollToFirstRowOnChange: true
                    }}
                    tableLayout="auto"
                    size={isMobile ? "small" : "middle"}
                    bordered
                    rowClassName={rowClassName}
                />
            </div>
        </Card>
    );
};

export default Deneme;
