// API URL ayarları
const config = {
  // Harici config dosyasındaki değerleri kullan, yoksa varsayılan değerleri kullan
  apiUrl: window.appConfig?.apiUrl || "https://doruknike.sehoffice.com/rest-api/nike",
  repoUrl: window.appConfig?.repoUrl || "https://doruknike.sehoffice.com/home/seziko/repo/",
  downloadUrl: window.appConfig?.downloadUrl || "https://doruknike.sehoffice.com/rest-api/download/",
  jitsiMeetUrl:window.appConfig?.jitsiMeetUrl ||"https://meet.sehoffice.com/"
  // Diğer konfigürasyon ayarları buraya eklenebilir
};

export default config; 