import React, { useState } from 'react';
import { Card, Button, Form, Select, Input, message, Space, Typography, Row, Col, InputNumber } from 'antd';
import { VideoCameraOutlined, SettingOutlined, UserOutlined, ClockCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import JitsiMeet from '../../components/JitsiMeet';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const { Option } = Select;
const { Title, Text } = Typography;

const TIME_TYPES = {
    second: 1000,        // 1 saniye = 1000 ms
    minute: 60 * 1000,   // 1 dakika = 60000 ms
    hour: 60 * 60 * 1000 // 1 saat = 3600000 ms
};

const Meet = () => {
    const navigate = useNavigate();
    const [meetForm] = Form.useForm();
    const [meetLoading, setMeetLoading] = useState(false);
    const [meetRoom, setMeetRoom] = useState(null);
    const [meetJwt, setMeetJwt] = useState(null);
    const [showMeetingArea, setShowMeetingArea] = useState(false);
    const user = JSON.parse(localStorage.authdata).username;


    const handleMeetCreate = async (values) => {
        setMeetLoading(true);
        try {
            // values.duration'ı number tipine çevirelim
            const duration = parseInt(values.duration);
            
            const response = await axios.post('/createMeet', {
                method: "createMeet",
                processor: "meet",
                data: {
                    operator: user,
                    moderator: values.moderator,
                    roomName: values.roomName
                }
            });

            if (response.data?.success && response.data?.data?.body) {
                const { jwt, roomName } = response.data.data.body;
                setMeetJwt(jwt);
                setMeetRoom(roomName);
                setShowMeetingArea(true);
                message.success('Toplantı başarıyla oluşturuldu');
                meetForm.resetFields();
            } else {
                message.error('Toplantı oluşturulurken bir hata oluştu');
            }
        } catch (error) {
            console.error('Toplantı oluşturma hatası:', error);
            message.error('Toplantı oluşturulurken bir hata oluştu: ' + (error.response?.data?.message || error.message));
        }
        setMeetLoading(false);
    };

    const handleMeetClose = async (roomName) => {
        try {
            await axios.post('/closeMeet', {
                method: "closeMeet",
                processor: "meet",
                data: {
                    roomName: roomName,
                    operator: user
                }
            });
            
            message.success('Toplantı sonlandırıldı');
            setMeetRoom(null);
            setMeetJwt(null);
            setShowMeetingArea(false);
            navigate('/');
        } catch (error) {
            console.error('Toplantı sonlandırma hatası:', error);
            message.error('Toplantı sonlandırılırken bir hata oluştu: ' + (error.response?.data?.message || error.message));
        }
    };

    const meetingSetupContent = (
        <Row
            justify="center"
            align="top"
            style={{
                width: '100%',
                margin: 0
            }}
        >
            <Col xs={24} sm={24} md={20} lg={16} xl={14}>
                <Card
                    className="meeting-setup-card"
                    style={{
                        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
                        borderRadius: '16px',
                        background: '#fff',
                        padding: '32px',
                        width: '100%'
                    }}
                    bodyStyle={{
                        padding: '0'
                    }}
                >
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            width: '100%'
                        }}
                    >
                        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                            <div style={{
                                background: '#f0f7ff',
                                borderRadius: '50%',
                                width: '100px',
                                height: '100px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '0 auto 24px'
                            }}>
                                <VideoCameraOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
                            </div>
                            <Typography.Title level={2} style={{ margin: '0 0 12px' }}>
                                Yeni Toplantı
                            </Typography.Title>
                            <Typography.Text type="secondary" style={{ fontSize: '18px' }}>
                                Hızlı ve kolay bir şekilde toplantı başlatın
                            </Typography.Text>
                        </div>

                        <Form
                            form={meetForm}
                            layout="vertical"
                            onFinish={handleMeetCreate}
                            style={{ marginTop: '24px' }}
                        >
                            <Form.Item
                                name="roomName"
                                rules={[{ required: true, message: 'Lütfen toplantı adını giriniz!' }]}
                            >
                                <Input
                                    size="large"
                                    prefix={<UserOutlined style={{ color: '#1890ff' }} />}
                                    placeholder="Toplantı Adı"
                                    id="roomName"
                                    style={{ borderRadius: '8px' }}
                                />
                            </Form.Item>

                            <Form.Item
                                label={
                                    <Space>
                                        <SettingOutlined style={{ color: '#1890ff' }} />
                                        <span>Moderatör Olarak Katıl</span>
                                    </Space>
                                }
                                name="moderator"
                                initialValue="true"
                            >
                                <Select
                                    size="large"
                                    style={{ borderRadius: '8px' }}
                                >
                                    <Option value="true">Evet</Option>
                                    <Option value="false">Hayır</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0, marginTop: '24px' }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    block
                                    icon={<VideoCameraOutlined />}
                                    style={{
                                        height: '48px',
                                        borderRadius: '8px',
                                        fontSize: '16px'
                                    }}
                                >
                                    Toplantıyı Başlat
                                </Button>
                            </Form.Item>
                        </Form>
                    </Space>
                </Card>
            </Col>
        </Row>
    );

    return (
        <div style={{ 
            padding: '24px',
            minHeight: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            background: '#f5f7fa'
        }}>
            {!showMeetingArea ? meetingSetupContent : (
                <div style={{ 
                    height: '90vh',
                    width: '90%',
                    margin: '0 auto',
                    background: '#fff',
                    borderRadius: '16px',
                    overflow: 'hidden',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
                }}>
                    <JitsiMeet 
                        roomName={meetRoom}
                        jwt={meetJwt}
                        onClose={handleMeetClose}
                    />
                </div>
            )}
        </div>
    );
};

export default Meet; 