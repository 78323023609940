import React, {useContext, useState} from 'react';
import { Button, Checkbox, Form, Input, Typography, Card } from 'antd';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {MainContext} from "../../context";
import {jwtDecode} from "jwt-decode";
import logo from "../../img/sportinn1.png";

const { Title, Text } = Typography;

const onFinish = (values) => {
};

const onFinishFailed = (errorInfo) => {
};

const Login = (props) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [isLoggedIn, setIsLoggedin] = useState(null);
    const [isLicence, setIsLicence] = useState(null);
    const [value, dispatch] = useContext(MainContext);
    const [token, setToken] = useState();

    const loginClicked = () => {
        if (username && password) {
            const data = { username, password };
            axios.post("/login", data)
                .then((res) => {
                    setIsLoggedin(true);
                    loginState(res);
                })
                .catch(error => {
                    setIsLoggedin(false);
                });
        }
    };

    const checkLicence = async () => {
        try {
            //const res = await axios.post("/checkLicence");
            setIsLicence(true);
            loginClicked(); // checkLicence başarılı olursa login işlemini başlat
        } catch (error) {
            setIsLicence(false);
        }
    };

    const handleLoginButtonClick = (event) => {
        checkLicence(); // İlk olarak lisans kontrolü yapılıyor
    };

    const loginState = (props) => {
        localStorage.setItem('authdata', JSON.stringify({
            signedIn: true,
            username: username,
            token: props.data.data,
            userRole: jwtDecode(props.data.data).userRole
        }));

        dispatch({
            type: "SIGN_IN",
            payload: {
                signedIn: true,
                username: username,
                token: token,
                userRole: jwtDecode(props.data.data).userRole
            },
        });

        navigate("/");
    };

    return (
        <div style={styles.container}>
            <Card style={styles.card}>
                <Title level={2} style={styles.title}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            src={logo}
                            alt="Logo"
                            style={{width: 200, height: 200, marginLeft: 40, cursor: 'pointer'}}
                        />
                    </div>
                </Title>
                <Form
                    name="login"
                    layout="vertical"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Kullanıcı Adı"
                        name="username"
                        rules={[{ required: true, message: 'Kullanıcı adı boş olamaz!' }]}
                    >
                        <Input
                            placeholder="Kullanıcı adınızı girin."
                            onChange={e => setUsername(e.target.value)}
                            style={styles.input}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Şifre"
                        name="password"
                        rules={[{ required: true, message: 'Şifre boş olamaz!' }]}
                    >
                        <Input.Password
                            placeholder="Şifrenizi girin"
                            onChange={e => setPassword(e.target.value)}
                            style={styles.input}
                        />
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked" style={styles.checkbox}>
                        <Checkbox>Beni Hatırla</Checkbox>
                    </Form.Item>

                    {isLoggedIn === false && (
                        <Form.Item>
                            <Text type="danger" style={styles.errorText}>
                                Kullanıcı adı ve ya şifre hatalı.
                            </Text>
                        </Form.Item>
                    )}

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={checkLicence}
                            style={styles.button}
                            block
                        >
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}

export default Login;

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
    },
    card: {
        width: 400,
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    input: {
        height: '40px',
        borderRadius: '5px',
    },
    checkbox: {
        textAlign: 'left',
    },
    button: {
        backgroundColor: '#1890ff',
        color: '#fff',
        borderRadius: '5px',
        height: '45px',
        fontSize: '16px',
    },
    errorText: {
        textAlign: 'center',
        marginTop: '-15px',
    },
};