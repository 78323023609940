import React, { useEffect, useRef, useState } from 'react';
import config from "../config";
import { useNavigate } from 'react-router-dom';

const JitsiMeet = ({ roomName, jwt, onClose }) => {
    const domain = config.jitsiMeetUrl.replace(/^https?:\/\//, '');
    const [api, setApi] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        if (!isClosing) {
            setIsClosing(true);
            if (onClose) {
                onClose(roomName);
            }
        }
    };

    // Logo gizleme için CSS ekle
    useEffect(() => {
        const style = document.createElement('style');
        style.textContent = `
            .watermark,
            .leftwatermark,
            .rightwatermark,
            .poweredby,
            .welcome-page-settings,
            #jitsiLogo,
            .welcome-logo,
            .deep-linking-mobile-logo,
            .header-image-container {
                display: none !important;
                opacity: 0 !important;
                visibility: hidden !important;
            }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    useEffect(() => {
        // Jitsi Meet API script'ini yükle
        const loadJitsiScript = () => {
            return new Promise((resolve) => {
                if (window.JitsiMeetExternalAPI) {
                    resolve();
                    return;
                }

                const script = document.createElement("script");
                script.src = `${config.jitsiMeetUrl}external_api.js`;
                script.async = true;
                script.onload = () => resolve();
                document.body.appendChild(script);
            });
        };

        // Toplantıyı başlat
        const initJitsi = async () => {
            if (!roomName || !jwt) {
                console.error("Oda adı veya JWT eksik");
                handleClose();
                return;
            }

            try {
                await loadJitsiScript();
                console.log("Jitsi script yüklendi");

                if (!window.JitsiMeetExternalAPI) {
                    throw new Error("JitsiMeetExternalAPI yüklenemedi");
                }

                console.log("Toplantı başlatılıyor:", { roomName, domain });

                const options = {
                    roomName: roomName,
                    jwt: jwt,
                    width: '100%',
                    height: '100%',
                    parentNode: containerRef.current,
                    configOverwrite: {
                        prejoinPageEnabled: false,
                        startWithAudioMuted: true,
                        startWithVideoMuted: false,
                        disableDeepLinking: true,
                        hideConferenceSubject: true,
                        hideConferenceTimer: false,
                        disableThirdPartyRequests: true,
                        disableLocalVideoFlip: true,
                        brandingDataUrl: null,
                        disableBrandWatermark: true,
                        defaultLogoUrl: null,
                        customizationReady: true,
                        disableDeepLinking: true,
                        enableLobby: true,
                        enableClosePage: false,
                        enableWelcomePage: false,
                        requireDisplayName: false,
                        enableInviteOthers: true,
                        enableEmailInStats: false
                    },
                    interfaceConfigOverwrite: {
                        SHOW_JITSI_WATERMARK: false,
                        SHOW_WATERMARK_FOR_GUESTS: false,
                        SHOW_BRAND_WATERMARK: false,
                        SHOW_POWERED_BY: false,
                        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                        MOBILE_APP_PROMO: false,
                        HIDE_DEEP_LINKING_LOGO: true,
                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                        DISABLE_VIDEO_BACKGROUND: true,
                        DISABLE_PRESENCE_STATUS: true,
                        DISABLE_TRANSCRIPTION_SUBTITLES: false,
                        DISABLE_RINGING: true,
                        DISABLE_WELCOME_PAGE_TITLE: true,
                        TOOLBAR_BUTTONS: [
                            // Temel Kontroller
                            'microphone', 'camera', 'hangup', 'desktop', 'fullscreen',
                            
                            // İletişim ve Etkileşim
                            'chat', 'raisehand', 'invite', 'participants-pane', 'mute-everyone', 'mute-video-everyone',
                            
                            // Görünüm ve Kalite
                            'tileview', 'videoquality', 'filmstrip', 'select-background',
                            
                            // Kayıt ve Paylaşım
                            'recording', 'livestreaming', 'sharedvideo',
                            
                            // Güvenlik ve Yönetim
                            'security', 'toggle-lobby', 'password',
                            
                            // Ayarlar ve Cihazlar
                            'fodeviceselection', 'settings', 'profile',
                            
                            // Yardımcı Özellikler
                            'closedcaptions', 'stats', 'shortcuts', 'help', 'download', 'feedback'
                        ],
                        DEFAULT_BACKGROUND: '#040404',
                        DEFAULT_REMOTE_DISPLAY_NAME: 'Katılımcı',
                        DEFAULT_LOCAL_DISPLAY_NAME: 'Siz',
                        JITSI_WATERMARK_LINK: '',
                        BRAND_WATERMARK_LINK: '',
                        PROVIDER_NAME: ''
                    }
                };

                console.log("Jitsi API oluşturuluyor");
                const jitsiApi = new window.JitsiMeetExternalAPI(domain, options);
                console.log("Jitsi API oluşturuldu");

                // Logo gizleme için ek komutlar
                jitsiApi.executeCommand('hideBrandWatermark');
                jitsiApi.executeCommand('hideWatermark');
                jitsiApi.executeCommand('hidePoweredBy');

                // Lobi ve şifre ayarları
                setTimeout(() => {
                    jitsiApi.executeCommand('toggleLobby', true);
                    jitsiApi.executeCommand('password', '12345');
                }, 2000);

                // Event listeners
                jitsiApi.addEventListeners({
                    readyToClose: () => {
                        console.log("Ready to close event triggered");
                        handleClose();
                    },
                    videoConferenceLeft: () => {
                        console.log("Video conference left event triggered");
                        handleClose();
                    },
                    participantLeft: () => {
                        console.log("Participant left event triggered");
                    },
                    participantJoined: () => {
                        console.log("Participant joined event triggered");
                    },
                    // Yeni event listener'lar
                    hangup: () => {
                        console.log("Hangup event triggered");
                        handleClose();
                    },
                    browserClosed: () => {
                        console.log("Browser closed event triggered");
                        handleClose();
                    }
                });

                // Pencere kapatıldığında veya sayfa yenilendiğinde
                window.addEventListener('beforeunload', () => {
                    handleClose();
                });

                setApi(jitsiApi);
                setIsLoading(false);
            } catch (error) {
                console.error("Jitsi Meet başlatma hatası:", error);
                handleClose();
            }
        };

        initJitsi();

        return () => {
            if (api) {
                api.dispose();
            }
            // Cleanup
            window.removeEventListener('beforeunload', handleClose);
        };
    }, [roomName, jwt, domain]);

    if (!roomName || !jwt) {
        return null;
    }

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            {isLoading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f0f2f5',
                    zIndex: 1
                }}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontSize: '18px', marginBottom: '10px' }}>Toplantı yükleniyor...</div>
                        <div style={{ fontSize: '14px', color: '#666' }}>Lütfen bekleyin...</div>
                    </div>
                </div>
            )}
            <div 
                ref={containerRef} 
                style={{ 
                    width: '100%', 
                    height: '100%',
                    visibility: isLoading ? 'hidden' : 'visible' 
                }} 
            />
        </div>
    );
};

export default JitsiMeet; 