import {Button, Modal, Table, Tooltip, Space, Card, Typography, Badge, Input, Row, Col, Tabs} from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined, ReloadOutlined, SearchOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';

import FileManagmentModal from './components/FileManagmentModal';
import FileUploadList from '../../FileUploadList';

const { TabPane } = Tabs;

const Deneme = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedPersonel, setSelectedPersonel] = useState(null); // Seçilen personel bilgisi
    const [personnelFileList, setPersonnelFileList] = useState([]);
    const [emptyFormList, setEmptyFormList] = useState([]); // Form doldurulmayan personel listesi
    const [emptyFormSize, setEmptyFormSize] = useState(0); // Form doldurulmayan personel sayısı
    const [companyName, setCompanyName] = useState(""); // Şirket ismi state
    const [searchText, setSearchText] = useState('');
    const [searchTextEmptyList, setSearchTextEmptyList] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingAllPersonnel, setLoadingAllPersonnel] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const scrollContainerRef = useRef(null);
    const scrollContainerEmptyRef = useRef(null);
    const [activeTab, setActiveTab] = useState("1");
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: isMobile ? 5 : 10,
            showSizeChanger: !isMobile,
        },
    });
    const [emptyTableParams, setEmptyTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: isMobile ? 5 : 10,
            showSizeChanger: !isMobile,
        },
    });

    useEffect(() => {
        // Ekran boyutu değişikliklerini dinle
        const handleResize = () => {
            const mobile = window.innerWidth <= 768;
            setIsMobile(mobile);
            setTableParams(prev => ({
                ...prev,
                pagination: {
                    ...prev.pagination,
                    pageSize: mobile ? 5 : 10,
                    showSizeChanger: !mobile,
                }
            }));
            setEmptyTableParams(prev => ({
                ...prev,
                pagination: {
                    ...prev.pagination,
                    pageSize: mobile ? 5 : 10,
                    showSizeChanger: !mobile,
                }
            }));
        };

        window.addEventListener('resize', handleResize);
        fetchPersonnelFileList(props.companyId);
        fetchEmptyFormList(props.companyId);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [props.companyId]);

    useEffect(() => {
        if (!showModal) {
            fetchPersonnelFileList(props.companyId); // Modal kapanınca yeniden dosya listesini yükle
            fetchEmptyFormList(props.companyId);
        }
    }, [showModal, props.companyId]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
        
        // Scroll'u en başa getir
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = 0;
        }
    };

    const handleEmptyTableChange = (pagination, filters, sorter) => {
        setEmptyTableParams({
            pagination,
            filters,
            ...sorter,
        });
        
        // Scroll'u en başa getir
        if (scrollContainerEmptyRef.current) {
            scrollContainerEmptyRef.current.scrollLeft = 0;
        }
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleSearchEmptyList = (value) => {
        setSearchTextEmptyList(value);
    };

    const refreshData = () => {
        if (activeTab === "1") {
            setLoading(true);
            fetchPersonnelFileList(props.companyId);
        } else {
            setLoadingAllPersonnel(true);
            fetchEmptyFormList(props.companyId);
        }
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
        if (key === "2" && emptyFormList.length === 0) {
            fetchEmptyFormList(props.companyId);
        }
    };

    const companyColumns = [
        {
            id: 1,
            title: "Mağaza",
            dataIndex: "company",
            render: () => companyName || "-",  // Dinamik olarak companyName gösteriliyor
        },
        {
            id: 2,
            title: "Personel",
            dataIndex: "personel",
            sorter: (a, b) => a.personel.localeCompare(b.personel),
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value, record) => {
                return record.personel.toLowerCase().includes(value.toLowerCase())
            }
        },
        {
            id: 3,
            title: (
                <Tooltip title="İş Sağlığı Güvenliği Eğitim Raporu">
                    <span>İSG Eğitim Belgesi</span>
                </Tooltip>
            ),
            dataIndex: "ISG_EGITIM_RAPORU",
            render: (text, item) => renderStatus(item, "ISG_EGITIM_RAPORU"),
        },
        {
            id: 4,
            title: (
                <Tooltip title="Periyodik Sağlık Raporu">
                    <span>Periyodik Sağlık Raporu</span>
                </Tooltip>
            ),
            dataIndex: "PERIYODIK_SAGLIK_RAPORU",
            render: (text, item) => renderStatus(item, "PERIYODIK_SAGLIK_RAPORU"),
        },
        {
            id: 5,
            title: (
                <Tooltip title="Tahlil Sonuçları">
                    <span>Tahlil Sonuçları</span>
                </Tooltip>
            ),
            dataIndex: "TAHLIL_SONUCLARI",
            render: (text, item) => renderStatus(item, "TAHLIL_SONUCLARI"),
        },
        {
            id: 6,
            title: (
                <Tooltip title="Ön Test">
                    <span>Ön Test</span>
                </Tooltip>
            ),
            dataIndex: "ON_TEST",
            render: (text, item) => renderStatus(item, "ON_TEST"),
        },
        {
            id: 7,
            title: (
                <Tooltip title="Son Test">
                    <span>Son Test</span>
                </Tooltip>
            ),
            dataIndex: "SON_TEST",
            render: (text, item) => renderStatus(item, "SON_TEST"),
        },
        {
            id: 8,
            title: (
                <Tooltip title="İş Sağlığı Güvenliği Taahhütname">
                    <span>İSG Taahhütname</span>
                </Tooltip>
            ),
            dataIndex: "ISG_TAAHHUTNAME",
            render: (text, item) => renderStatus(item, "ISG_TAAHHUTNAME"),
        },
    ];

    const emptyPersonnelColumns = [
        {
            title: "Mağaza",
            dataIndex: "company",
            render: (company) => company ? company.name : "-",
        },
        {
            title: "Personel",
            dataIndex: "name",
            render: (text, record) => `${record.name || ''} ${record.surname || ''}`,
            sorter: (a, b) => (a.name + ' ' + (a.surname || '')).localeCompare(b.name + ' ' + (b.surname || '')),
            filteredValue: searchTextEmptyList ? [searchTextEmptyList] : null,
            onFilter: (value, record) => {
                const fullName = `${record.name || ''} ${record.surname || ''}`.toLowerCase();
                return fullName.includes(value.toLowerCase());
            }
        },
        {
            title: "Unvan",
            dataIndex: "unvan",
        },
        {
            title: "Başlangıç Tarihi",
            dataIndex: "startDate",
            render: (date) => date ? new Date(date).toLocaleDateString('tr-TR') : "-"
        }
    ];

    const renderStatus = (item, fileType) => {
        const file = item.files.find(f => f.fileType === fileType);
        if (!file) return "-";  // İlgili fileType yoksa "-" göster

        const handleClick = () => {
            handleRowClick(item, fileType);
        };

        if (file.approved) {
            return (
                <span onClick={handleClick}>
                    <CheckCircleOutlined style={{ color: 'green' }} /> Onaylı
                </span>
            );
        }

        if (file.reject) {
            return (
                <span onClick={handleClick}>
                    <CloseCircleOutlined style={{ color: 'red' }} /> Ret
                </span>
            );
        }

        if (file.timeout) {
            return (
                <span onClick={handleClick}>
                    <ClockCircleOutlined style={{ color: 'orange' }} /> Süresi Geçmiş
                </span>
            );
        }

        return (
            <span onClick={handleClick}>
                <QuestionCircleOutlined /> Onay Bekliyor
            </span>
        );
    };

    const fetchEmptyFormList = (companyId) => {
        setLoadingAllPersonnel(true);
        
        // Yeni endpoint'i kullanarak form doldurulmayan personel listesini çek
        axios.post('/findPersonelListNotHaveForm', { 
            method: "findPersonelListNotHaveForm", 
            processor: "personelManagement", 
            data: { 
                companyId: companyId
            } 
        })
        .then(response => {
            if (response.data && response.data.success) {
                // Gelen veriyi doğrudan emptyFormList state'ine kaydet
                setEmptyFormList(response.data.data.data || []);
                setEmptyFormSize(response.data.data.size || 0);
            } else {
                setEmptyFormList([]);
                setEmptyFormSize(0);
                console.error("Form doldurulmayan personel listesi alınamadı:", response.data.errorMessage);
            }
            setLoadingAllPersonnel(false);
        })
        .catch(error => {
            console.error("Form doldurulmayan personel listesi alınırken hata oluştu:", error);
            setEmptyFormList([]);
            setEmptyFormSize(0);
            setLoadingAllPersonnel(false);
        });
    };

    const fetchPersonnelFileList = (companyId) => {
        setLoading(true);
        const sendData = {
            id: companyId
        };

        axios.post('/findFilesCompanyPersonel', { method: "findFilesCompanyPersonel", processor: "file", data: sendData })
            .then(response => {
                const rawData = response.data.data;
                const rawList = rawData.personelFileList;

                // Gelen veriyi tabloya uygun formatta dönüştürüyoruz
                const formattedList = Object.keys(rawList).map(personel => ({
                    personel,
                    companyId,
                    files: rawList[personel]  // İlgili dosyalar
                }));

                setPersonnelFileList(formattedList);
                setCompanyName(rawData.companyName);
                setLoading(false);
            })
            .catch((error) => {
                console.error("An error occurred while fetching personnel file data: ", error);
                setLoading(false);
            });
    };

    // Satır tıklama eventi
    const handleRowClick = (record, fileType) => {
        if (!fileType) {
            return;
        }
        
        const filteredFile = record.files.find(f => f.fileType === fileType);

        if (filteredFile) {
            setShowModal(false);
            
            setTimeout(() => {
                const modifiedRecord = {
                    personel: record.personel,
                    files: [filteredFile],
                    company: record.company,
                    companyId: record.companyId,
                };
                
                setSelectedPersonel(modifiedRecord);
                setShowModal(true);
            }, 100);
        } else {
        }
    };

    // Filtreleme için kullanılan veri
    const filteredData = personnelFileList.filter(
        item => item.personel.toLowerCase().includes(searchText.toLowerCase())
    );

    // Form doldurulmayan personel listesi için arama filtresi
    const filteredEmptyFormList = emptyFormList.filter(item => {
        const fullName = `${item.name || ''} ${item.surname || ''}`.toLowerCase();
        return fullName.includes(searchTextEmptyList.toLowerCase());
    });

    // Satır sınıfı belirlemek için
    const rowClassName = (record, index) => {
        const baseClass = 'custom-row';
        // Belirli kriterlere göre ek sınıflar eklenebilir
        return baseClass;
    };

    // Komponentin yeniden renderlanmasında scrollu başa getir
    useEffect(() => {
        if (activeTab === "1" && scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = 0;
        } else if (activeTab === "2" && scrollContainerEmptyRef.current) {
            scrollContainerEmptyRef.current.scrollLeft = 0;
        }
    }, [props.companyId, searchText, searchTextEmptyList, activeTab]);

    return (
        <Card 
            title={
                <Space>
                    <Typography.Title level={isMobile ? 5 : 4} style={{ margin: 0 }}>
                        Personel Bazlı Belgeler
                    </Typography.Title>
                </Space>
            }
            extra={
                <Space>
                    <Button 
                        type="primary" 
                        icon={<ReloadOutlined />} 
                        onClick={refreshData}
                        loading={activeTab === "1" ? loading : loadingAllPersonnel}
                    >
                        {!isMobile && "Yenile"}
                    </Button>
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={() => setShowUploadModal(true)}
                    >
                        {!isMobile && "Belge Yükle"}
                    </Button>
                </Space>
            }
        >
            <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                <TabPane 
                    tab={
                        <span>
                            Form Yüklenen Personeller
                            <Badge 
                                count={personnelFileList.length} 
                                showZero 
                                style={{ marginLeft: 8 }}
                                color="#1890ff" 
                            />
                        </span>
                    } 
                    key="1"
                >
                    <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                        <Col xs={24} md={12} lg={8}>
                            <Input.Search
                                placeholder="Personel adı ara..."
                                allowClear
                                onSearch={handleSearch}
                                onChange={(e) => setSearchText(e.target.value)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>

                    <div 
                        ref={scrollContainerRef} 
                        style={{ overflowX: 'auto', width: '100%' }}
                    >
                        <Table
                            columns={companyColumns}
                            dataSource={filteredData}
                            rowKey="personel"
                            loading={loading}
                            onChange={handleTableChange}
                            pagination={tableParams.pagination}
                            scroll={{ 
                                x: isMobile ? 1200 : 1800,
                                y: undefined,
                                scrollToFirstRowOnChange: true
                            }}
                            tableLayout="auto"
                            size={isMobile ? "small" : "middle"}
                            bordered
                            rowClassName={rowClassName}
                        />
                    </div>
                </TabPane>
                <TabPane 
                    tab={
                        <span>
                            Form Doldurulmayan Personel Listesi
                            <Badge 
                                count={emptyFormSize} 
                                showZero 
                                style={{ marginLeft: 8 }}
                                color="#ff4d4f" 
                            />
                        </span>
                    } 
                    key="2"
                >
                    <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                        <Col xs={24} md={12} lg={8}>
                            <Input.Search
                                placeholder="Personel adı ara..."
                                allowClear
                                onSearch={handleSearchEmptyList}
                                onChange={(e) => setSearchTextEmptyList(e.target.value)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                    
                    <div 
                        ref={scrollContainerEmptyRef} 
                        style={{ overflowX: 'auto', width: '100%' }}
                    >
                        <Table
                            columns={emptyPersonnelColumns}
                            dataSource={filteredEmptyFormList}
                            rowKey="id"
                            loading={loadingAllPersonnel}
                            onChange={handleEmptyTableChange}
                            pagination={emptyTableParams.pagination}
                            scroll={{ 
                                x: '100%',
                                y: undefined,
                                scrollToFirstRowOnChange: true
                            }}
                            tableLayout="auto"
                            size={isMobile ? "small" : "middle"}
                            bordered
                            rowClassName={rowClassName}
                            locale={{ emptyText: 'Tüm personeller için form doldurulmuş' }}
                        />
                    </div>
                </TabPane>
            </Tabs>

            <Modal
                title="Personel Bazlı Detay"
                footer={
                    <Button type="primary" onClick={() => setShowModal(false)}>
                        Kapat
                    </Button>
                }
                width={1300}
                open={showModal}
                centered={true}
                onCancel={() => setShowModal(false)}
                destroyOnClose={true}
                key={selectedPersonel?.personel + '-' + selectedPersonel?.files[0]?.fileType}
            >
                {selectedPersonel && (
                    <FileManagmentModal
                        key={selectedPersonel.personel + '-' + selectedPersonel.files[0]?.fileType}
                        personel={selectedPersonel.personel}
                        companyId={selectedPersonel.companyId}
                        documentType={selectedPersonel.files[0]?.fileType}
                    />
                )}
            </Modal>

            {/* Belge Yükleme Modalı */}
            <Modal
                title="Belge Yükleme"
                footer={null}
                width={1300}
                open={showUploadModal}
                centered={true}
                onCancel={() => {
                    setShowUploadModal(false);
                    refreshData();
                }}
                destroyOnClose={true}
            >
                <FileUploadList 
                    defaultActiveKey="2"
                    selectedCompany={props.companyId}
                    onClose={() => {
                        setShowUploadModal(false);
                        refreshData();
                    }}
                />
            </Modal>
        </Card>
    );
}

export default Deneme;
